<template>
  <b-card>
    <b-row>
      <b-col>{{ $t("home.driver.model") }}</b-col>
      <b-col>{{ info.vehicle.model }}</b-col>
    </b-row>
    <b-row>
      <b-col>{{ $t("home.driver.date-loaded") }}</b-col>
      <b-col>{{ $d(new Date(info.loadedBy.time)) }}</b-col>
    </b-row>
    <div v-if="info.previousDriver != null">
      <b-row>
        <b-col>{{ $t("home.driver.date-checked-out") }}</b-col>
        <b-col>{{ $d(new Date(info.previousDriver.time)) }}</b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>{{ $t("home.driver.checked-out-by") }}</b-col>
        <b-col>
          {{ info.previousDriver.name }}
          <phone-number :phoneNumber="info.previousDriver.phone" />
        </b-col>
      </b-row>
    </div>
    <div v-if="info.imageIds.length">
      <b-row class="mb-2">
        <b-col>{{ $t("home.driver.photos-of-incidents") }}</b-col>
      </b-row>
      <incident-thumbnail-image v-for="(item, i) in info.imageIds" :key="i" :imageId="item" />
    </div>
  </b-card>
</template>

<script>
import PhoneNumber from "@/components/PhoneNumber.vue";
import IncidentThumbnailImage from "@/components/incident/IncidentThumbnailImage.vue";

export default {
  components: {
    IncidentThumbnailImage,
    PhoneNumber
  },
  props: {
    info: { type: Object, required: true }
  }
};
</script>
