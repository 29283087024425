<template>
  <div>
    <b-form @submit.prevent inline>
      <b-input
        v-model.trim="registrationNumber"
        :placeholder="$t('vehicle-searcher.reg-number-placeholder')"
        class="mr-2"
      ></b-input>
      <b-button @click="onSearchBtnClick" variant="primary">
        {{ $t("vehicle-searcher.search-btn-caption") }}
      </b-button>
    </b-form>
  </div>
</template>

<script>
import api from "@/services/api.js";
export default {
  data() {
    return {
      registrationNumber: ""
    };
  },
  methods: {
    onSearchBtnClick() {
      if (this.registrationNumber === "") {
        alert(this.$t("vehicle-searcher.error.empty-reg-number"));
        return;
      }
      api
        .getVehicleByRegistrationNumber(this.registrationNumber)
        .then(resp => this.$router.push({ name: "vehicle-acceptance", params: { vehicleId: resp.data.id } }))
        .catch(err => {
          if (err.status == 404) {
            alert(this.$t("vehicle-searcher.error.not-found"));
          } else {
            alert(this.$t("vehicle-searcher.error.general"));
          }
        });
    }
  }
};
</script>
