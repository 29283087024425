<template>
  <div class="overflow-y" :style="css" ref="panelContainer">
    <collapse-panel
      :ref="PANELS.ACCEPTED_VEHICLE_PANEL"
      :key="1"
      :expanded.sync="findPanelByName(PANELS.ACCEPTED_VEHICLE_PANEL).expanded"
      :title="acceptedVehiclePanelTitle"
      :maxHeight="findPanelByName(PANELS.ACCEPTED_VEHICLE_PANEL).maxHeight"
      header-btn
      sub-header
      @expand="onExpand(PANELS.ACCEPTED_VEHICLE_PANEL)"
      @expandToFullSccreen="onExpandToFullScreen(PANELS.ACCEPTED_VEHICLE_PANEL)"
    >
      <template #headerBtn>
        <router-link v-if="hasAcceptedVehicle" :to="{ name: 'vehicle-checkout', params: { vehicleId: vehicleId } }">
          <b-button>{{ $t("home.driver.checkout") }}</b-button>
        </router-link>
      </template>
      <template #subHeader>
        <vehicle-short-info v-if="hasAcceptedVehicle" :info="info"></vehicle-short-info>
      </template>
      <template #default>
        <vehicle-additional-info v-if="hasAcceptedVehicle" :info="info"></vehicle-additional-info>
        <vehicle-searcher v-else></vehicle-searcher>
      </template>
    </collapse-panel>
    <collapse-panel
      v-if="hasAcceptedVehicle"
      :ref="PANELS.VEHICLE_HISTORY_PANEL"
      :key="2"
      :expanded.sync="findPanelByName(PANELS.VEHICLE_HISTORY_PANEL).expanded"
      :title="vehicleHistoryPanelTitle"
      :maxHeight="findPanelByName(PANELS.VEHICLE_HISTORY_PANEL).maxHeight"
      @expand="onExpand(PANELS.VEHICLE_HISTORY_PANEL)"
      @expandToFullSccreen="onExpandToFullScreen(PANELS.VEHICLE_HISTORY_PANEL)"
    >
      <template #default>
        <vehicle-history-table :vehicleId="vehicleId"></vehicle-history-table>
      </template>
    </collapse-panel>
    <collapse-panel
      v-if="hasAcceptedVehicle"
      :ref="PANELS.VEHICLE_GOODS_PANEL"
      :key="3"
      :expanded.sync="findPanelByName(PANELS.VEHICLE_GOODS_PANEL).expanded"
      :title="vehicleGoodsPanelTitle"
      :maxHeight="findPanelByName(PANELS.VEHICLE_GOODS_PANEL).maxHeight"
      @expand="onExpand(PANELS.VEHICLE_GOODS_PANEL)"
      @expandToFullSccreen="onExpandToFullScreen(PANELS.VEHICLE_GOODS_PANEL)"
    >
      <template #default>
        <vehicle-goods-table :vehicleId="vehicleId"></vehicle-goods-table>
      </template>
    </collapse-panel>
    <collapse-panel
      v-if="hasAcceptedVehicle"
      :ref="PANELS.VEHICLE_INCIDENTS_PANEL"
      :key="4"
      :expanded.sync="findPanelByName(PANELS.VEHICLE_INCIDENTS_PANEL).expanded"
      :title="vehicleIncidentsPanelTitle"
      :maxHeight="findPanelByName(PANELS.VEHICLE_INCIDENTS_PANEL).maxHeight"
      header-btn
      @expand="onExpand(PANELS.VEHICLE_INCIDENTS_PANEL)"
      @expandToFullSccreen="onExpandToFullScreen(PANELS.VEHICLE_INCIDENTS_PANEL)"
    >
      <template #headerBtn>
        <router-link :to="{ name: 'incident-open-list' }">
          <b-button>{{ $t("home.driver.vehicle-incidents-panel.details") }}</b-button>
        </router-link>
      </template>
      <template #default>
        <vehicle-incidents-table :vehicleId="vehicleId"></vehicle-incidents-table>
      </template>
    </collapse-panel>
    <collapse-panel
      :ref="PANELS.DRIVER_SALES_PANEL"
      :key="5"
      :expanded.sync="findPanelByName(PANELS.DRIVER_SALES_PANEL).expanded"
      :title="driverSalesPanelTitle"
      :maxHeight="findPanelByName(PANELS.DRIVER_SALES_PANEL).maxHeight"
      @expand="onExpand(PANELS.DRIVER_SALES_PANEL)"
      @expandToFullSccreen="onExpandToFullScreen(PANELS.DRIVER_SALES_PANEL)"
    >
      <template #default>
        <driver-sales-table></driver-sales-table>
      </template>
    </collapse-panel>
  </div>
</template>

<script>
import CollapsePanel from "../CollapsePanel.vue";
import VehicleAdditionalInfo from "./panels/accepted-vehicle-panel/VehicleAdditionalInfo.vue";
import VehicleShortInfo from "./panels/accepted-vehicle-panel/VehicleShortInfo.vue";
import VehicleHistoryTable from "./panels/vehicle-history/VehicleHistoryTable";
import VehicleIncidentsTable from "./panels/vehicle-incidents/VehicleIncidentsTable";
import VehicleGoodsTable from "./panels/vehicle-goods-panel/VehicleGoodsTable.vue";
import DriverSalesTable from "./panels/driver-sales/DriverSalesTable.vue";
import VehicleSearcher from "@/components/vehicle/VehicleSearcher.vue";

import panelService from "@/services/panel-service.js";

import * as PANELS from "@/const/driver-panels.js";

export default {
  components: {
    CollapsePanel,
    VehicleAdditionalInfo,
    VehicleShortInfo,
    VehicleSearcher,
    VehicleHistoryTable,
    VehicleIncidentsTable,
    VehicleGoodsTable,
    DriverSalesTable
  },
  props: {
    offsetTop: { type: Number, required: true }
  },
  data() {
    return {
      PANELS,
      info: null,
      panels: panelService.getDefaultPanelSettings()
    };
  },
  computed: {
    acceptedVehiclePanelTitle() {
      const title = this.$t("home.driver.current-vehicle");

      if (this.info) {
        return `${title} ${this.info.vehicle.registrationNumber}`;
      }

      return title;
    },
    vehicleHistoryPanelTitle() {
      return this.$t("home.driver.vehicle-history-panel.title", { plateNumber: this.info.vehicle.registrationNumber });
    },
    vehicleIncidentsPanelTitle() {
      return this.$t("home.driver.vehicle-incidents-panel.title", {
        plateNumber: this.info.vehicle.registrationNumber
      });
    },
    vehicleGoodsPanelTitle() {
      return this.$t("home.driver.vehicle-goods-panel.title");
    },
    driverSalesPanelTitle() {
      return this.$t("home.driver.sales-panel.title");
    },
    hasAcceptedVehicle() {
      return !!this.info;
    },
    vehicleId() {
      return this.info ? this.info.vehicle.id : 0;
    },
    css() {
      const height = document.body.offsetHeight - this.offsetTop - 10;

      return {
        "min-height": `${height}px`,
        "max-height": `${height}px`
      };
    }
  },
  methods: {
    updateHeight() {
      if (this.panels.some(x => x.expandedToFullScreen)) {
        const nonexpandedToFullScreens = this.panels.filter(x => !x.expandedToFullScreen);

        nonexpandedToFullScreens.forEach(panel => {
          if (this.$refs[panel.name]) {
            panel.maxHeight = this.$refs[panel.name].$el.offsetHeight;
          }
        });

        const expandedToFullScreen = this.panels.find(x => x.expandedToFullScreen);

        if (expandedToFullScreen) {
          const height = nonexpandedToFullScreens.reduce((acc, p) => acc + p.maxHeight, 0);

          expandedToFullScreen.maxHeight = this.$refs.panelContainer.offsetHeight - height;
          expandedToFullScreen.expanded = true;
        }
      }
    },
    onExpand(panelName) {
      this.resetPanelSettings();
      this.$store.dispatch("userSettings/updatePanelSetting", this.findPanelByName(panelName));
    },
    onExpandToFullScreen(panelName) {
      const panel = this.findPanelByName(panelName);

      if (!panel.expandedToFullScreen) {
        this.resetPanelSettings();

        this.panels.forEach(p => {
          p.expanded = false;
          p.expandedToFullScreen = p.name === panelName;
        });

        this.$store.dispatch("userSettings/updatePanelSetting", panel).then(() => {
          this.updateHeight();
        });
      }
    },
    resetPanelSettings() {
      this.panels.forEach(panel => {
        panel.expandedToFullScreen = false;
        panel.maxHeight = null;
      });
    },
    findPanelByName(name) {
      return this.panels.find(x => x.name === name);
    }
  },
  mounted() {
    Promise.all([panelService.getPanelSettings(), this.$store.dispatch("vehicleLoadAudit/getDriverHomePageInfo")]).then(
      promises => {
        const panels = promises[0];
        const homePangeInfo = promises[1].data;

        if (!homePangeInfo) {
          let acceptedVehiclePanel = panels.find(x => x.name === PANELS.ACCEPTED_VEHICLE_PANEL);

          if ((acceptedVehiclePanel && !acceptedVehiclePanel.expanded) || !acceptedVehiclePanel.expandedToFullScreen) {
            acceptedVehiclePanel.expanded = true;
          }
        }

        this.info = homePangeInfo;
        this.panels = panels;

        this.$nextTick(() => this.updateHeight());
      }
    );
  }
};
</script>
