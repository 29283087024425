<template>
  <b-table :fields="fields" :items="vehicleGoods" small responsive>
    <template #cell(thumbnail)="data">
      <thumbnail-image v-if="data.item.imageId" :imageId="data.item.imageId"></thumbnail-image>
    </template>
    <template #cell(title)="data">
      {{ data.item.title[$i18n.locale] }}
    </template>
    <template #cell(count)="data">
      {{ data.item.count }}
    </template>
  </b-table>
</template>

<script>
import ThumbnailImage from "@/components/goods-images/ThumbnailImage.vue";

export default {
  components: {
    ThumbnailImage
  },
  props: {
    vehicleId: { type: Number, required: true }
  },
  computed: {
    vehicleGoods() {
      return this.$store.getters["vehicleGoods/goods"];
    },
    fields() {
      return [
        { key: "thumbnail", label: this.$t("home.driver.vehicle-goods-panel.thumbnail") },
        { key: "title", label: this.$t("home.driver.vehicle-goods-panel.goodsTitle") },
        { key: "count", label: this.$t("home.driver.vehicle-goods-panel.count") }
      ];
    }
  },
  mounted() {
    this.$store.dispatch("vehicleGoods/load", this.vehicleId);
  }
};
</script>
