import api from "./api.js";
import store from "@/store";

import { DRIVER } from "@/const/user-role.js";
import * as DRIVER_PANELS from "@/const/driver-panels.js";

export default {
  getPanelSettings,
  getDefaultPanelSettings
};

function getPanelSettings() {
  const defaultPanelSettings = getDefaultPanelSettings();

  return api.getPanelSettings().then(resp => {
    const settings = defaultPanelSettings.map(x => {
      const panel = resp.data.find(p => p.name === x.name);

      return panel ? panel : x;
    });

    return Promise.resolve(settings);
  });
}

function getDefaultPanelSettings() {
  const role = store.state.userSettings.userInfo.role;

  switch (role) {
    case DRIVER: {
      return [
        createPanel(DRIVER_PANELS.ACCEPTED_VEHICLE_PANEL),
        createPanel(DRIVER_PANELS.VEHICLE_HISTORY_PANEL),
        createPanel(DRIVER_PANELS.VEHICLE_GOODS_PANEL),
        createPanel(DRIVER_PANELS.VEHICLE_INCIDENTS_PANEL),
        createPanel(DRIVER_PANELS.DRIVER_SALES_PANEL)
      ];
    }
    default:
      return [];
  }
}

function createPanel(panelName) {
  return {
    name: panelName,
    expanded: false,
    expandedToFullScreen: false,
    maxHeight: null
  };
}
