<template>
  <b-table :items="items" :fields="fields" small responsive>
    <template v-slot:cell(loadedBy)>
      <span v-if="info.loadedBy.name">
        <div>{{ info.loadedBy.name }}</div>
        <div>
          <phone-number :phoneNumber="info.loadedBy.phone"></phone-number>
        </div>
      </span>
    </template>
  </b-table>
</template>

<script>
import PhoneNumber from "@/components/PhoneNumber.vue";

export default {
  components: {
    PhoneNumber
  },
  props: {
    info: { type: Object }
  },
  computed: {
    fields() {
      return [
        { key: "vehicleRegistrationNumber", label: this.$t("home.driver.number") },
        { key: "vehicleBrand", label: this.$t("home.driver.brand") },
        { key: "time", label: this.$t("home.driver.date-accepted") },
        { key: "loadedBy", label: this.$t("home.driver.loaded-by") }
      ];
    },
    items() {
      return this.info == null
        ? []
        : [
            {
              vehicleRegistrationNumber: this.info.vehicle.registrationNumber,
              vehicleBrand: this.info.vehicle.brand,
              time: this.$d(new Date(this.info.driver.time))
            }
          ];
    }
  }
};
</script>
