<style scoped>
.modal {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content-img {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  border: 0;
  animation-name: zoom;
  animation-duration: 0.3s;
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
}
@media only screen and (max-width: 700px) {
  .modal-content-img {
    width: 100%;
  }
}
</style>

<template>
  <b-container class="d-inline p-1">
    <b-img thumbnail fluid :src="thumbnailImage" class="thumbnail cursor-pointer" @click="openFullImage"></b-img>
    <div v-if="showFullImage" class="modal">
      <span @click="hideFullImage" class="close cursor-pointer">&times;</span>
      <b-img :src="fullImg" @click="hideFullImage" class="modal-content-img"></b-img>
    </div>
  </b-container>
</template>

<script>
import api from "@/services/api.js";
export default {
  props: {
    imageId: { type: String }
  },
  data() {
    return {
      showFullImage: false,
      thumbnailImage: "",
      fullImg: ""
    };
  },
  methods: {
    loadImage() {
      this.thumbnailImage = "";
      if (this.imageId) {
        api.getThumbnail(this.imageId).then(resp => {
          this.thumbnailImage =
            "data:application/octet-stream;base64," + new Buffer(resp.data, "binary").toString("base64");
        });
      }
    },
    openFullImage() {
      api.getImage(this.imageId).then(resp => {
        this.fullImg = "data:application/octet-stream;base64," + new Buffer(resp.data, "binary").toString("base64");
        this.showFullImage = true;
      });
    },
    hideFullImage() {
      this.showFullImage = false;
    }
  },
  mounted() {
    this.loadImage();
  }
};
</script>
