<template>
  <div>
    <b-table :fields="fields" :items="vehicleHistory" small responsive>
      <template v-slot:cell(checkedInAt)="data">
        {{ data.item.checkedInAt ? $d(Date.parse(data.item.checkedInAt), "log") : "" }}
      </template>
      <template v-slot:cell(checkedInBy)="data">
        {{ data.item.checkedInBy.userName }}
        <phone-number :phoneNumber="data.item.checkedInBy.phone" />
      </template>
      <template v-slot:cell(checkedOutAt)="data">
        {{ data.item.checkedOutAt ? $d(Date.parse(data.item.checkedOutAt), "log") : "" }}
      </template>
      <template v-slot:cell(checkedOutBy)="data">
        {{ data.item.checkedOutBy.userName }}
        <phone-number :phoneNumber="data.item.checkedOutBy.phone" />
      </template>
    </b-table>
  </div>
</template>

<script>
import PhoneNumber from "@/components/PhoneNumber.vue";

export default {
  components: {
    PhoneNumber
  },
  props: {
    vehicleId: { type: Number, required: true }
  },
  computed: {
    fields() {
      return [
        { key: "checkedInAt", label: this.$t("home.driver.vehicle-history-panel.checked-in-at") },
        { key: "checkedInBy", label: this.$t("home.driver.vehicle-history-panel.checked-in-by") },
        { key: "checkedOutAt", label: this.$t("home.driver.vehicle-history-panel.checked-out-at") },
        { key: "checkedOutBy", label: this.$t("home.driver.vehicle-history-panel.checked-out-by") },
        { key: "issuesCreated", label: this.$t("home.driver.vehicle-history-panel.issues-created") }
      ];
    },
    vehicleHistory() {
      return this.$store.state.vehicleHistory.items;
    }
  },
  mounted() {
    this.$store.dispatch("vehicleHistory/load", this.vehicleId);
  }
};
</script>
