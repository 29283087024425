<template>
  <b-container>
    <b-row ref="row">
      <b-col>
        <h2>{{ $t("home.driver.title", { userName: userName }) }}</h2>
      </b-col>
    </b-row>
    <driver-dashboard :offsetTop="offsetTop"></driver-dashboard>
  </b-container>
</template>

<script>
import DriverDashboard from "@/components/user/driver/DriverDashboard.vue";

import auth from "@/services/auth.js";

export default {
  components: {
    DriverDashboard
  },
  data() {
    return {
      offsetTop: 0
    };
  },
  computed: {
    userName: () => auth.userName()
  },
  mounted() {
    this.offsetTop = document.getElementById("navigation-bar").offsetHeight + this.$refs.row.offsetHeight;
  }
};
</script>
