<template>
  <b-card no-body :style="cardStyle" header-class="py-1">
    <template #header>
      <b-container fluid class="p-0 m-0" ref="header">
        <b-row align-v="center">
          <b-col class="cursor-pointer p-0 m-0" @click="onTogglePanel">
            <h5 class="no-user-select">{{ title }}</h5>
          </b-col>
          <b-col v-if="headerBtn" class="text-center">
            <slot name="headerBtn"></slot>
          </b-col>
          <b-col class="text-right p-0 m-0" cols="auto">
            <b-button @click="onExpandToFullSccreen" class="d-inline">
              <v-icon name="expand"></v-icon>
            </b-button>
            <b-button class="ml-2 d-inline" @click.prevent="onTogglePanel">
              <v-icon :name="expanded ? 'arrow-circle-up' : 'arrow-circle-down'"></v-icon>
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="subHeader">
          <slot name="subHeader"></slot>
        </b-row>
      </b-container>
    </template>
    <template #default>
      <div v-show="expanded" class="p-2" :class="{ 'overflow-y': expanded }">
        <slot></slot>
      </div>
    </template>
  </b-card>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    expanded: { type: Boolean, required: true },
    maxHeight: { type: Number },
    headerBtn: { type: Boolean },
    subHeader: { type: Boolean }
  },
  computed: {
    cardStyle() {
      if (this.maxHeight) {
        return { "max-height": `${this.maxHeight}px` };
      }
      return {};
    }
  },
  methods: {
    onTogglePanel() {
      this.$emit("update:expanded", !this.expanded);
      this.$emit("expand");
    },
    onExpandToFullSccreen() {
      this.$emit("expandToFullSccreen");
    }
  }
};
</script>

<style scoped>
.gray-bg {
  background-color: #f3f3f3;
  border-radius: 0.25rem;
}
.no-user-select {
  user-select: none;
}
</style>
