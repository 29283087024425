<template>
  <div>
    <b-table :fields="fields" :items="vehicleIncidents" small responsive>
      <template v-slot:cell(reportedAt)="data">
        {{ data.item.reportedAt ? $d(Date.parse(data.item.reportedAt), "log") : "" }}
      </template>
      <template v-slot:cell(reportedBy)="data">
        {{ data.item.reportedBy.userName }}
        <phone-number :phoneNumber="data.item.reportedBy.phone" />
      </template>
      <template v-slot:cell(type)="data">{{ getTypeTitle(data.item.type, data.item.issueCategoryId) }}</template>
      <template v-slot:cell(status)="data">
        {{ $t(`incident-status.${data.item.status}`) }}
      </template>
    </b-table>
  </div>
</template>

<script>
import PhoneNumber from "@/components/PhoneNumber.vue";

import { CUSTOM_INCIDENT_TYPE } from "@/const/incident-types.js";

export default {
  components: {
    PhoneNumber
  },
  props: {
    vehicleId: { type: Number, required: true }
  },
  computed: {
    fields() {
      return [
        { key: "title", label: this.$t("incident.title") },
        { key: "reportedBy", label: this.$t("home.driver.vehicle-incidents-panel.reported-by") },
        { key: "reportedAt", label: this.$t("home.driver.vehicle-incidents-panel.reported-at") },
        { key: "type", label: this.$t("home.driver.vehicle-incidents-panel.type") },
        { key: "status", label: this.$t("home.driver.vehicle-incidents-panel.status") }
      ];
    },
    vehicleIncidents() {
      return this.$store.state.vehicleIncidents.items;
    },
    issueCategories() {
      return this.$store.state.issueCategories.items;
    }
  },
  methods: {
    getTypeTitle(type, issueCategoryId) {
      if (type == CUSTOM_INCIDENT_TYPE) {
        let issueCategory = this.issueCategories.find(x => x.id == issueCategoryId);
        if (issueCategory) return issueCategory.title[this.$i18n.locale];
      } else {
        return this.$t(`incident-type.${type}`);
      }
    }
  },
  mounted() {
    this.$store.dispatch("vehicleIncidents/load", this.vehicleId);
    this.$store.dispatch("issueCategories/load");
  }
};
</script>
