<template>
  <b-table :fields="fields" :items="driverSales" small responsive>
    <template #cell(image)="data">
      <thumbnail-image v-if="data.item.imageId" :imageId="data.item.imageId"></thumbnail-image>
    </template>
    <template #cell(title)="data">
      {{ data.item.title[$i18n.locale] }}
    </template>
  </b-table>
</template>

<script>
import ThumbnailImage from "@/components/goods-images/ThumbnailImage.vue";

export default {
  components: {
    ThumbnailImage
  },
  computed: {
    fields() {
      return [
        { key: "image", label: this.$t("home.driver.sales-panel.image") },
        { key: "title", label: this.$t("home.driver.sales-panel.goods-title"), sortable: true },
        { key: "accounted", label: this.$t("home.driver.sales-panel.accounted"), sortable: true },
        { key: "notAccounted", label: this.$t("home.driver.sales-panel.not-accounted"), sortable: true }
      ];
    },
    driverSales() {
      return this.$store.state.userSales.items;
    }
  },
  mounted() {
    this.$store.dispatch("userSales/load");
  }
};
</script>
